<!--
 * @Descripttion: 
 * @version: 
 * @Author: xyh
 * @Date: 2021-04-19 17:10:38
 * @LastEditTime: 2021-04-23 21:08:15
-->
<template>
  <div class="pay_detail">
    <div class="header">
      <a-icon type="left" />
      <span class="ret" @click="toTaskManage" style="padding-left: 10px; color: #999999;cursor:pointer"
        >任务管理/</span
      >
      <span style="color: #999999;cursor:pointer" @click="goBackToDetail">任务详情/</span><span>付款单详情</span>
    </div>
    <div class="detail_status">
      <div class="common_title">付款结算单</div>
      <div style="padding-left: 10px"  v-if="hanNoRegister >0">
        
          <img src="../../assets/tips.png" alt="" style="width:14px;height:14px;">
        
        <span style="padding-left:10px;font-size:14px;cursor:pointer;"
          >当前有<span style="color: #1890ff" @click="importDetailModal = true">{{hanNoRegister}}人未注册，</span
          >未出现在付款结算单中</span
        >
      </div>
    </div>
    <div class="alert_info">
      <div class="icon"><a-icon type="info-circle" /></div>
      <div class="txt">
        请您严格审核自由职业者上传的服务成果，成果必须与发布的任务相关且真实有效，服务成果包括但不请您以认真谨慎的态度上传有效的服务成果。服务成果须一目了然的反映出您从事的业务内容及完成情况，服务成果包括但不限于：服务人员本人在服务场景中的照片（能够看到产品或企业logo）、设计效果图、服务的方案/计划书、绩效费发放规则、公司系统中的销售及提佣数据、专业技术服务对应的服务成果等(支持doc、docx、xls、xlsx、pdf、jpg等格式)，以上成果证据均需企业或自然人签字（盖章）。如服务成果不能提供客观图像资料，可能无法通过平台开具发票。限于：销售方本人在服务场景中的照片、设计策划稿件照片、汽车租赁的产权证、商品采购对上游的汇款凭证、专业技术服务对应的服务成果图片。如服务成果不能提供客观图像资料，可能无法通过本平台开具发票。
      </div>
    </div>
    <div class="search">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :lg="8" :md="8" :sm="12">
            <a-form-item label="用户名称">
              <a-input
                style="width: 200px"
                v-model="queryParam.userName"
                placeholder="请输入姓名"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="8" :sm="24">
            <a-form-item label="审核状态">
              <a-select
                style="width: 200px"
                placeholder="请选择审核状态"
                v-model="queryParam.status"
              >
                <a-select-option value="10">待上传</a-select-option>
                <a-select-option value="20">待支付</a-select-option>
                <a-select-option value="30">支付中</a-select-option>
                <a-select-option value="11">已上传</a-select-option>
                <a-select-option value="12">待确认</a-select-option>
                <a-select-option value="40">已支付</a-select-option>
                <a-select-option value="50">已取消</a-select-option>
                <a-select-option value="51">已拒绝</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="8" :sm="12">
            <span class="table-page-search-submitButtons">
              <a-button
                style="margin-left: 12px"
                type="primary"
                @click="checkPayDetailTable"
                >查询</a-button
              >
              <a-button
                style="margin-left: 12px"
                icon="reload"
                @click="resetPayDetailTable"
                >重置</a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="upload">
        <div class="badge">
        
          <span
            class="head-example"
            style="color: #1890ff;cursor:pointer;padding-right:10px;"
            @click="batchNotifyConfirm"
            >批量通知</span
          >
        
        <span
            class="head-example"
            style="color: #1890ff;cursor:pointer"
            @click="plUpload"
            >批量上传</span
          >
      </div>
      <div class="status_btn">
        <a-button type="primary" @click="submitPay">提交审核付款</a-button>
        
      </div>
      
    </div>

        

    <div class="detail_table">
      <s-table
        ref="payDetail"
        style="border-top: 1px solid #f0f0f0; margin-top: 16px"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columnsBmry"
        :data="dataBmry"
        :rowKey="(record) => record.id"
      >
      <span
          slot="result"
          slot-scope="text, record"
          
        >
          <a
            @click="showPic(record)"
            v-if="record.payStatus > '10'"
            >查看</a
          >
          
          
        </span>
        <span
          slot="action"
          slot-scope="text, record"
          
        >
          <a
            @click="uploadResult(record)"
            v-if="record.payStatus == '10'"
            style="padding-right:10px"
            >上传</a
          >
          <a
            @click="notifyConfirm(record)"
            v-if="record.payStatus == '12'"
            
            >通知确认</a
          >
          <a
            @click="uploadResult(record)"
            v-if="record.payStatus == '12'"
            style="padding-left:10px"
            >重新上传</a
          >
          <a
            @click="cancelPay(record)"
            v-if="record.payStatus == '10'"
            style="padding-left:10px"
            >取消付款</a
          >
        </span>
        <span slot="userName" slot-scope="text, record">
          <a-popover title="">
            <template slot="content">
              <div class="pop-content">
                <div>
                  <img class="img" :src="record.avatarUrl" alt="" />
                </div>
                <div>
                  <div class="person">
                    <span>{{ record.userName }}</span
                    ><span>{{ record.phoneNo }}</span>
                  </div>
                  <div class="construction">{{ record.oneselfRemark }}</div>
                </div>
              </div>
              <div class="tags">{{ record.oneselfLable }}</div>
            </template>
            <a-button style="padding: 0" type="link">
              {{ record.userName }}
            </a-button>
          </a-popover>
        </span>
        <span slot="signStatus" slot-scope="text">
          <div class="table-point" v-if="text === '10'">
            <span style="background: #1890ff"></span> 已报名
          </div>
          <div class="table-point" v-else-if="text === '92'">
            <span style="background: #52c41a"></span> 已拒绝
          </div>
          <div class="table-point" v-else>
            <span style="background: #bfbfbf"></span> 已取消
          </div>
        </span>
      </s-table>
    </div>

    <!-- 导入名单详情 -->
    <div>
      <a-modal
        title="导入名单详情"
        width="1000px"
        :visible="importDetailModal"
        :footer="null"
        @cancel="handleCancelPl"
      >
        <div  style="width:900px">
          <a-form layout="inline">
            <a-row :gutter="2">
              <a-col :lg="8" :md="8" :sm="12">
                <a-form-item label="用户名称" style="width: 300px">
                  <a-input
                    
                    v-model="queryParamImport.userName"
                    placeholder="请输入姓名"
                  />
                </a-form-item>
              </a-col>
              <a-col :lg="8" :md="8" :sm="24">
                <a-form-item label="注册状态">
                  <a-select
                    style="width: 200px"
                    placeholder="请选择注册状态"
                    v-model="queryParamImport.loginStatus"
                  >
                    <a-select-option value="0">未注册</a-select-option>
                    <a-select-option value="1">已注册</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :lg="8" :md="8" :sm="12">
                <span class="table-page-search-submitButtons">
                  <a-button
                    style="margin-left: 12px"
                    type="primary"
                    @click="handleToSearchEnterprises"
                    >查询</a-button
                  >
                  <a-button
                    style="margin-left: 12px"
                    icon="reload"
                    @click="resetSearchEnterprises"
                    >重置</a-button
                  >
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="tip_info">
          <img src="../../assets/tips.png" alt="" style="width:14px;height:14px;">
          <span style="padding-left:10px;font-size:14px;color:#333;">未注册人员无法出现在付款结算单</span>
        </div>
        <div class="pl-table">
          <s-table
            ref="importDetail"
            :columns="columnsPl"
            :data="taskDataPl"
            :rowKey="(record) => record.id"
            :scroll="{ y: 300 }"
          >
            <span slot="action" slot-scope="text, record">
              <a v-if="record.loginStatus === 0" @click="sendMsg(record)"
                >发送短信</a
              >
            </span>
          </s-table>
        </div>
      </a-modal>
    </div>

    <!-- 统一上传 -->

    <div>
      <a-modal title="上传任务结果" :confirmLoading="confirmLoadingUpload" width="600px" destroyOnClose :visible="uploadTaskResultModal" @ok="uploadTaskResultOk" @cancel="uploadTaskResultOkCancel">
              <a-form :form="uploadResultPl">
                <a-row class="form-row">
                  <a-col :lg="24" :md="24" :sm="24">
                    <a-form-item label="任务结果" :labelCol="{lg: {span: 4}, sm: {span: 5}}" :wrapperCol="{lg: {span: 20}, sm: {span: 19} }">
                      <a-upload
                        :action="url.frontUrl"
                        :multiple="true"
                        :headers="headers"
                        :file-list="fileList"
                        :data="uploadData"
                        :beforeUpload="beforeUpload"
                        @change="handleChange"
                      >
                        <a-button> <a-icon type="upload" /> 上传 </a-button>
                      </a-upload>
                    </a-form-item>
                  </a-col>
                  <a-col :lg="24" :md="24" :sm="24">
                    <a-form-item label="备注" :labelCol="{lg: {span: 4}, sm: {span: 5}}" :wrapperCol="{lg: {span: 20}, sm: {span: 19} }">
                      <a-textarea placeholder="请填写备注" v-decorator="['content', {rules: [{ required: false, message: '请填写备注', whitespace: true}]}]"></a-textarea>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </a-modal>
    </div>
    
    
    <!-- 任务结果查看 -->
    <div>
        <a-modal
                title="任务结果查看"
                :visible="checkResultModal"
                :footer="null"
                @cancel="checkResultModal=false"
              >
                <ul class="pic-select">
                  <li v-for="(item,index) in resultFileList.items" :key="index"><a :href="item.fileUrl" target="_blank">{{item.fileName===null?`自由职业者任务成果${index+1}`:item.fileName}}</a></li>
                  <li>任务结果描述：{{resultFileList.content}}</li>
                </ul>
                <!-- <a-carousel arrows>
                  <div
                    slot="prevArrow"
                    slot-scope="props"
                    class="custom-slick-arrow"
                    style="left: 10px;zIndex: 1"
                    :dots='position'
                  >
                    <a-icon type="left-circle" />
                  </div>
                  <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
                    <a-icon type="right-circle" />
                  </div>
                  <div v-for="(item,index) in picList" :key="index">
                    <img v-if="item.fileUrl.indexOf('png')!==-1||item.fileUrl.indexOf('jpg')!==-1||item.fileUrl.indexOf('jpeg')!==-1||item.fileUrl.indexOf('JPEG')!==-1||item.fileUrl.indexOf('bmp')!==-1||item.fileUrl.indexOf('tif')!==-1||item.fileUrl.indexOf('gif')!==-1||item.fileUrl.indexOf('pcx')!==-1||item.fileUrl.indexOf('tga')!==-1||item.fileUrl.indexOf('exif')!==-1||item.fileUrl.indexOf('fpx')!==-1||item.fileUrl.indexOf('svg')!==-1||item.fileUrl.indexOf('psd')!==-1||item.fileUrl.indexOf('cdr')!==-1||item.fileUrl.indexOf('pcd')!==-1||item.fileUrl.indexOf('dxf')!==-1||item.fileUrl.indexOf('ufo')!==-1||item.fileUrl.indexOf('eps')!==-1||item.fileUrl.indexOf('ai')!==-1||item.fileUrl.indexOf('raw')!==-1||item.fileUrl.indexOf('WMF')!==-1||item.fileUrl.indexOf('webp')!==-1||item.fileUrl.indexOf('avif')!==-1" :src="item.fileUrl" alt="" style="width:500px;height:400px;">
                    <div style="height:80px;line-height:80px;text-align:left;font-size:18px">任务结果描述：{{currentContent}}</div>
                  </div>
                </a-carousel> -->
              </a-modal>
    </div>
  </div>
</template>

<script>
import STable from "@/components/table/";
import { ACCESS_TOKEN } from "@/store/mutation-types"
import Vue from 'vue'
import {
  raskDetail,
  raskCancel,
  raskEnd,
  getWorkList,
  raskAssign,
  refuseAssign,
  cancelAssign,
  getRaskTypeName,
  taskWorkerPayItem,
  queryImportWorkerList,
  importWorkerStatistics,
  importWorkerStatisticsChannel,
  channelConfirmPayList,
  getOrderList,
  enterpriseUnifyAdd,
  prePay,
  getBalance,
  getUserBindEmail,
  enterpriseAdd,
  batchUploadResultChannel,
  uploadResultChannel,
  notifyConfirm,
  getResultChannel,
  getNoRegisterNumChanner,
  sendInvitationSms,
  submitPeriodPay,
  batchNotifyConfirm,
  cancelPay,
} from '@/api/api'
export default {
  data() {
    return {
      queryParam: {
        userName: "",
        status: undefined,
      },
      queryParamImport: {
        userName: "",
        loginStatus: undefined,
      },
      columnsBmry: [
        {
          title: '姓名',
          dataIndex: 'userName',
          scopedSlots: { customRender: 'userName' },
        },
        {
          title: '付款金额',
          dataIndex: 'payAmount',
          customRender: text => text === null?'--':(Math.floor(text * 100) / 100).toFixed(2),
          
        },
        {
          title: '联系电话',
          dataIndex: 'phoneNo',
          customRender: text => text===null?'--':text
        },
        {
          title: '来源',
          dataIndex: 'joinType',
          customRender: text => text=== '10' ? '报名' : '邀请'
        },
        {
          title: '状态',
          dataIndex: 'payStatus',
          customRender: text => {
            if (text === '10') {
              return '待上传'
            }
            if (text === '11') {
              return '已上传'
            }
            if (text === '12') {
              return '待确认'
            }
             if (text === '20') {
              return '待支付'
            }
            if (text === '30') {
              return '支付中'
            }
            if (text === '40') {
              return '已支付'
            }
            if (text === '50') {
              return '已取消'
            }
            if (text === '51') {
              return '已拒绝'
            }
          }
        },
        {
          title: '任务结果',
          dataIndex: 'result',
          scopedSlots: { customRender: 'result' },
        },
        
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        }
      ],
      
      selectedRowKeys: [],
      importDetailModal:false,
       columnsPl: [
        {
          title: '姓名',
          dataIndex: 'userName',
          width: '100px'
        },
        {
          title: '电话',
          dataIndex: 'phoneNo',
          width: '150px'
        },
        {
          title: '付款金额',
          dataIndex: 'payAmount',
          customRender: text => text === null?'--':(Math.floor(text * 100) / 100).toFixed(2),
          width: '100px'
        },
        
        {
          title: '注册状态',
          dataIndex: 'loginStatus',
          customRender: text => text === 1?'已注册':'未注册',
          width: '100px'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '100px',
          scopedSlots: { customRender: 'action' },
        },
      ],
      dataPl:[],
      taskId:'',//payId
      outSideTaskId:'',//最外面的taskId
      confirmLoadingUpload:false,
      uploadTaskResultModal:false,
      uploadResultPl: this.$form.createForm(this),
      fileList: [],
      url: {
        frontUrl: window._CONFIG['domianURL'] + '/api/tax/common/file/upload',
      },
      headers: {'X-Access-Token': Vue.ls.get(ACCESS_TOKEN)},
      uploadData: {
        fileType: '103206'
      },
      isPl:false,//批量上传状态  true为批量
      
      payOrderModal:false,//付款模态框
      payOrderLoading:false,//付款加载按钮状态
      accountInfo:{},//账户信息
      userEmail:'',//账户邮箱
      resultFileList:[],
      checkResultModal:false,//查看任务结果模态框
      currentContent:'',
      hanNoRegister:0,
      currentSelectedKeys:[],
      notifyChooseArr:[],//批量通知
      uploadChooseArr:[],//批量上传
    };
  },
  components: {
    STable,
  },
  created () {
      this.taskId = this.$route.params.id;
      this.outSideTaskId = window.sessionStorage.getItem('outSideTaskId');
     console.log('outSideTaskId', window.sessionStorage.getItem('outSideTaskId'));
     this.getNoRegisterNumChanner()
  },
  methods: {
      //取消付款
      cancelPay(record){
          
          
          


        this.$confirm({
        title: '确定取消付款？',
        onOk:()=> {
            return new Promise((resolve, reject) => {
            cancelPay(record.id).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.checkPayDetailTable()
                
                resolve();
              } else {
                this.$message.error(res.message);
                reject();
              }
            });
            }).catch(() => console.log('Oops errors!'));
          
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
      },
      //返回到任务详情
      goBackToDetail(){
          this.$router.go(-1);
          window.localStorage.setItem('submenu','taskDetail')
      },
      //任务管理
      toTaskManage(){
          this.$router.push('/taskCenter')
          window.localStorage.setItem('submenu','taskCenter')
      },
      //查询是否有未注册的
      getNoRegisterNumChanner(){
          getNoRegisterNumChanner(this.taskId).then(res=>{
              if(res.success){
                  this.hanNoRegister = res.result;
              }
          })
      },
      dataBmry(parameter){
          // this.queryParam.payId = this.taskId
          //  return taskWorkerPayItem(Object.assign(parameter,this.queryParam)).then(res => {
        return taskWorkerPayItem(Object.assign({payId:this.taskId,},this.queryParam,parameter)).then(res => {
        // console.log(res)
        
        return res.result
      })
      },
      // 查询批量上传工作者列表
    taskDataPl(parameter) {
      return queryImportWorkerList(Object.assign(this.queryParamImport,{batchId:this.taskId},parameter)).then(res => {
        this.dataPl = res.result.data
        this.allowSubmit = res.result.data.length===0?false:true
        return res.result
      })
    },
    handleCancelPl(){
        this.importDetailModal = false;
    },
    //查看批量导入明细 重置
    resetSearchEnterprises(){
        this.queryParamImport= {
        userName: "",
        auditStatus: undefined,
      }
      this.$refs.importDetail.refresh(true)

    },
    ///查看批量导入明细 查询
    handleToSearchEnterprises(){
        this.$refs.importDetail.refresh(true)
    },
    checkPayDetailTable(){
        this.$refs.payDetail.refresh(true)
    },
    resetPayDetailTable(){
        this.queryParam= {
        userName: "",
        auditStatus: undefined,
      }
      this.$refs.payDetail.refresh(true)
    },
    // 批量上传
    plUpload () {
        if(this.uploadChooseArr.length ===0){
            this.$message.warning('请先选择用工人员')
            return
        }
      this.isPl = true
      this.uploadTaskResultModal = true
    },
    // 单个上传任务结果
    uploadResult (record) {
      this.isPl = false
      this.uploadRecord = record
      this.uploadTaskResultModal = true
    },
    //上传任务批量
    uploadTaskResultOk(){
        
        this.uploadResultPl.validateFields((err, fieldsValue) => {
        if (err) {
          return;
        }
        if (this.fileList.length===0) {
          this.$message.error('请上传任务结果！')
          return
        }
        let isUpload = true
        this.fileList.forEach(item=>{
          if (item.fileName===undefined) {
            item.status='error'
            isUpload = false
          }
        })
        if (!isUpload) {
          this.$message.error('上传文件中有错误文件，请删除后提交')
          return
        }
        this.confirmLoadingUpload = true
        const description = this.uploadResultPl.getFieldsValue();
        if (this.isPl) {
            if(this.uploadChooseArr.length ===0 ){
            this.$message.warning('请先选择用工人员')
            console.log(this.uploadChooseArr);
            return
            }
            //批量上传
          let params = {
            itemIds: this.uploadChooseArr,
            resultItems: this.fileList,
            content: description.content,
            taskId: this.outSideTaskId
          }
          batchUploadResultChannel(Object.assign(params)).then((res) => {
            this.confirmLoadingUpload = false
            if (res.success) {
              this.$message.success(res.message);
              this.selectedRowKeys = [];
              this.$refs.payDetail.refresh(true);
              this.uploadTaskResultOkCancel();
              this.uploadTaskResultModal = false;
            } else {
              this.$message.error(res.message);
            }
          })
        } else {
            //单个上传
          let params = {
            results:[
              {
                payItemId: this.uploadRecord.id,
                workerId: this.uploadRecord.workerId,
                resultItems: this.fileList,
                content: description.content
              }
            ],
            taskId: this.outSideTaskId
          }
          uploadResultChannel(Object.assign(params)).then((res) => {
            this.confirmLoadingUpload = false
            if (res.success) {
              this.$message.success(res.message);
              this.$refs.payDetail.refresh(true);
              this.uploadTaskResultOkCancel();
              this.uploadTaskResultModal = false;
            } else {
              this.$message.error(res.message);
            }
          })
        }
      })
    },
    //批量上传任务取消
    uploadTaskResultOkCancel(){
        this.uploadResultPl.resetFields();
      this.fileList = [];
      this.confirmLoadingUpload = false;
      this.uploadTaskResultModal = false;
    },
    //文件上传钩子
    beforeUpload(file) {
      const isJpgOrPng = true;
      if (!isJpgOrPng) {
        this.$message.error('上传照片格式不正确!');
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过10MB!');
        return false
      }
      return isJpgOrPng && isLt2M;
    },
    //文件上传
    handleChange(info) {
      let fileList = [...info.fileList];
      // 1. Limit the number of uploaded files
      fileList = fileList.slice(-9);
      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = 'https://oss.zfycloud.com' + "/" + file.response.result;
          file.fileUrl = 'https://oss.zfycloud.com' + "/" + file.response.result;
          file.fileName = file.name;
        }
        return file;
      });
      this.fileList = fileList;
    },
    // 付款信息  付款模态框取消按钮
     handleCancelFk () {
      this.payOrderModal = false
      this.payOrderLoading = false
      this.payPwd = ''
    },
    // 付款
    Fk () {
      getBalance('bank').then(res => {
        if (res.success) {
          this.accountInfo = res.result
        }
      })
      getUserBindEmail().then(res => {
        // console.log(res)
        if(res.success) {
          this.userEmail = res.message
        }
      })
      this.payOrderModal = true
    },
    // 立即付款
    ljfk () {
      // this.visibleFk = false
      this.payOrderLoading = true
      if (this.payPwd === '') {
        this.$message.error('支付密码不能为空')
        this.payOrderLoading = false
        return
      }
      prePay({payId:this.taskId}).then(res => {
        if (res.success) {
          if (res.result.useAllBalance===1) {
            taskPay({payId:this.taskId,prePayId:res.result.prePayId,pwd:this.payPwd}).then(result => {
              this.payOrderLoading = false
              if (result.success) {
                // this.getTaskDetail()
                // this.handleCancelFk()
                this.$refs.payDetail.refresh(true);
                this.$message.success(result.message)

              } else {
                this.$message.error(result.message)
              }
            })
          } else {
            this.payOrderLoading = false
            this.$message.error('余额不足，请充值！')
          }
        }
      })
      // if (this.accountInfo.normalBalanceAmount<(this.taskDetail.payAmount+this.taskDetail.payFeeAmount)) {
      //   this.$message.error('余额不足，请充值！')
      //   this.btnLoading = false
      // } else {
      //   if (this.payPwd === '') {
      //     this.$message.error('支付密码不能为空')
      //     this.btnLoading = false
      //     return
      //   }
      //   prePay({payId:this.taskId.id}).then(res => {
      //     if (res.success) {
      //       taskPay({payId:this.taskId.id,prePayId:res.result.prePayId,pwd:this.payPwd}).then(result => {
      //         this.btnLoading = false
      //         if (result.success) {
      //           this.getTaskDetail()
      //           this.handleCancelFk()
      //           this.$message.success(result.message)
      //         } else {
      //           this.$message.error(result.message)
      //         }
      //       })
      //     }
      //   })
      // }
    },
    notifyConfirm(record){
        let params = {
            "taskId": this.outSideTaskId,
            "userId": record.userId,
            "workerId": record.workerId
        }
        notifyConfirm(params).then(res=>{
            if(res.success){
                this.$message.success(res.message)
            }
        })
    },
    //查看任务结果
    
    showPic(record){
        console.log(55);
        
        getResultChannel(record.id).then(res=>{
            if(res.success){
                this.resultFileList = res.result;
                this.checkResultModal = true;
            }
        })
    },
    // 发送短信
    sendMsg (record) {
      sendInvitationSms(record.phoneNo).then(res => {
        if (res.success) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 提交结算
    submitPay() {
      let that = this
      this.$confirm({
        title: '确定提交结算？',
        onOk() {
            return new Promise((resolve, reject) => {
            submitPeriodPay({payId:that.taskId,taskId:that.outSideTaskId}).then((res) => {
              if (res.success) {
                that.$message.success(res.message);
                
                window.localStorage.setItem('submenu','settlementDetail')
                window.sessionStorage.setItem('taskInfo',JSON.stringify(res.result))
                window.location.reload(true)
                // that.$router.push('/settlementDetail')
                resolve();
              } else {
                that.$message.error(res.message);
                reject();
              }
            });
            }).catch(() => console.log('Oops errors!'));
          
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    },
    //批量通知
    batchNotifyConfirm(){
        if(this.notifyChooseArr.length ===0){
            this.$message.warning('请先选择用工人员')
            return
        }
        let params = {
            taskId:this.outSideTaskId,
            users:this.notifyChooseArr
        }
        batchNotifyConfirm(params).then(res=>{
            if(res.success){
                this.$message.success(res.message)
            }else{
                this.$message.error(res.message);
            }
        })
    },
    
    // 表格操作
    onSelectChange(selectedRowKeys,selectedRows) {
        console.log('selectedRows',selectedRows);
      let sek = []
      
      //批量通知
      let notifyChoose =[]
      //批量上传
      let uploadChoose = []
      selectedRows.forEach((item,index) => {
        uploadChoose.push(item.id)
        sek.push(item.id)
        let obj ={}
        obj.userId = item.userId;
        obj.workerId = item.workerId;
        notifyChoose.push(obj)
      })
      this.selectedRowKeys = sek;
    //   this.currentSelectedKeys = JSON.parse(JSON.stringify(sek))
      this.notifyChooseArr = notifyChoose;
      this.uploadChooseArr = uploadChoose;
      console.log('this.selectedRowKeys',this.selectedRowKeys)
      console.log('uploadChooseArr',this.currentSelectedKeys)
      console.log('notifyChoose',notifyChoose)
      
    },
  },
};
</script>

<style lang="less">
.pay_detail {
  padding: 14px;
}
.pic-select{
  padding: 0;
  margin: 0;
  list-style: none;
  li{
    margin: 10px 0;
  }
}
.header {
  color: #000000;
  font-size: 14px;
  margin-bottom: 20px;
  .ret {
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
  }
}
.header-one {
  padding: 27px 24px;
  border: 1px solid #f0f0f0;
  margin-bottom: 16px;
  // border-left: 0;
  // border-right: 0;
  // border-bottom: 0;
}
.detail_status {
  display: flex;
}
.common_title {
  font-size: 16px;
  color: #333;
  padding-left: 9px;
  border-left: 2px solid #347cf5;
  margin-bottom: 20px;
}
.alert_info {
  background: #fffbe6;
  color: #808080;
  font-size: 12px;
  padding: 13px 15px 13px 0px;
  display: flex;
  .icon {
    padding-right: 10px;
    padding-left: 1px;
  }
}
.search {
  margin: 13px 0;
}
.upload {
  display: flex;
  justify-content: space-between;
}
.tip_info {
  margin: 12px 0 13px 0;
  color: #5c5c5c;
  font-size: 12px;
}
</style>